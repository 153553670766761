import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Table from '@sm/components/custom/Table';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/clients.css';
import loadingIcon from '@assets/images/loading.png';
import PSPIconSingle from '@assets/images/psp_single_wl.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class PSPs extends Component {
  state = {
    psps: [],
    companies: [],
    availablePspStatuses: [{
      value: "ACTIVE",
      label: "Active"
    }, {
      value: "DEACTIVATED",
      label: "Deactivated"
    }],
    controls: new Form({
      company: "",
      pspStatus: []
    }, [{
      name: "companyIds",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "pspStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }}]),
    selectedCompany: "",
    searchPattern: "",
    access: [],
    isSuggestionBoxOpen: false,
    roleId: "",
    showProviders: false,
    pspStatusLoader: [],
    showAlert: false,
    alertType: "error",
    alertMessage: "",
    isDropDownOpen: false,
    openDropdown: null,
    editableStatus: false,
    newStatus: false,
    showPromptModal: false,
    changeValueMessage: "",
    editablePspId: "",
    editableStatusId: "",
    changeActivePropertyLoading: "",

    columns: [{
      value: "name",
      label: "Provider Name"
    },{
      value: "id",
      label: "API"
    }, store.default.getState().authReducer.roleId === "MASTER_TECH" && {
      value: "shortName",
      label: "Provider Short Name"
    }, {
      value: "companyIds",
      label: "White Label"
    }, {
      value: "active",
      label: "Status"
    },{
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-provider",
      isAction: true
    }]
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controls, availablePspStatuses } = this.state;
    document.addEventListener("mousedown", this.handleClickOutside);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId,
        showProviders: roleId === "WL_ADMIN" || roleId === "WL_USER"
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        const roleId = storeState.roleId;
        this.setState({
          access: state.access,
          roleId,
          showProviders: roleId === "WL_ADMIN" || roleId === "WL_USER"
        });
      }
    });

    crudActions.get("v1/companies/labels").then(companies => {
      if (companies) {
        this.setState({
          companies: companies,
          controls: Object.assign(controls, {
            company: companies[0].value,
          }),
          ...(companies.length === 1 && { selectedCompany: companies[0].value })
        }, () => {
          if (companies.length === 1) {
            this.onUpdateClick();
          }
        });
      }
    });

    this.setState({
      controls:  Object.assign(controls, {
        pspStatus: [availablePspStatuses.find(elem => elem.value === "ACTIVE").value]
      }),
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onSuggestionClick(pspName) {
    this.setState({
      searchPattern: pspName,
      isSuggestionBoxOpen: false
    });
  };

  searchSuggests(isSuggestion) {
    const { psps, searchPattern, selectedCompany } = this.state;

    let sortedProviders = psps;
    if (selectedCompany) {
      sortedProviders = psps.filter(psp => psp.companyIds.includes(selectedCompany));
    }

    const searchValue = searchPattern.toLowerCase();
    if (!searchValue) {
      return isSuggestion ? [] : this.prepareTableData(sortedProviders);
    }

    sortedProviders = sortedProviders.filter(psp => {
      return psp.name.toLowerCase().includes(searchValue) || psp.shortName.toLowerCase().includes(searchValue) || psp.id.toString().includes(searchValue);
    });

    return this.prepareTableData(sortedProviders);
  };

  getCurrencyAndMethodDropDown = (currencyAndMethods, providerId, field) => {
    const { pspStatusLoader, openDropdown } = this.state;
    const isPayin = field === 'currencyAndMethods';
    const displayedItems = currencyAndMethods.length > 0
      ? currencyAndMethods.slice(0, 2)
        .map(currencyMethod => currencyMethod.currency)
        .join(', ')
      : 'Select...';

    return <div className="dropdown">
      <button
        onClick={ (event) => {
          this.toggleDropdown(providerId, isPayin);
        } }
        className="dropdown-btn">
        { displayedItems } { currencyAndMethods.length > 2 ? '...' : '' }
      </button>
      { openDropdown && openDropdown.id === providerId && ((openDropdown.isPayin && isPayin) || (!openDropdown.isPayin && !isPayin))  && (
        <div className="dropdown-content">
          { currencyAndMethods.length === 0 ? (
            <div className="dropdown-item no-options">No options</div>
          ) : (
            currencyAndMethods.map((currencyAndMethod, index) => {
              const { currency, method, enabled } = currencyAndMethod;
              return (
                <label key={ index } className="dropdown-item"
                  onClick={ (event) => this.onTogglerClick(currencyAndMethod, providerId, !enabled, field) }>
                  <span>{ currency } + { method }</span>
                  <div className="toggle-switch">
                    { pspStatusLoader.some((item) => item.psId === providerId && item.currency === currency && item.method === method && item.isPayin === isPayin) ?
                      <div className="loading-wrapper">
                        <img src={ loadingIcon } alt="loading" style={ { height: "25px" } }/>
                      </div>
                      : <Toggler
                        active={ enabled }
                        disabled={ false }
                        value={ { currencyAndMethod } }
                      />
                    }
                  </div>
                </label>
              );
            })
          ) }
        </div>
      ) }
    </div>;
  };

  getActiveSelect = (activeStatus, providerId) => {
    const { availablePspStatuses, editableStatus, editableStatusId } = this.state;

    return editableStatus && editableStatusId === providerId ? (
      <Select
        className={ "active-status" }
        value={ activeStatus ? "ACTIVE" : "DEACTIVATED" }
        clearable={ false }
        options={ availablePspStatuses }
        onChange={ (e) => this.onChangeActiveStatus(activeStatus, e.value ) }
      />
    ) : (
      <span
        className={ "active-status-edit" }
        style={ { paddingRight: activeStatus ? "50px" : "0px" } }
        onDoubleClick={ () => this.onDoubleClickStatus(activeStatus, providerId) }
      >
        { activeStatus ? "Active" : "Deactivated" }
      </span>
    );
  };

  onDoubleClickStatus = (activeStatus, pspId) => {
    this.setState({
      editablePspId: pspId,
      editableStatus: true,
      editableStatusId: pspId
    });
  };

  onChangeActiveStatus = (activeStatus, newValue) => {
    if (newValue !== activeStatus) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to change the Status?`,
        newStatus: newValue === "ACTIVE",
      });
    }
  };

  prepareTableData = (sortedProviders) => {
    const { companies, selectedCompany } = this.state;
    return sortedProviders.map(provider => {

      return {
        ...provider,
        companyIds: companies.find(company => company.value === selectedCompany)?.label || '',
        active: this.getActiveSelect(provider.active, provider.id),
        enabled: this.getCurrencyAndMethodDropDown(provider.currencyAndMethods, provider.id, "currencyAndMethods"),
        enabledPayout: this.getCurrencyAndMethodDropDown(provider.currencyAndMethodsPayout, provider.id, "currencyAndMethodsPayout"),
      };
    });
  };

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((psp, index) => {
            return <div className='suggestion' key={ psp.shortName + index }
              onClick={ () => this.onSuggestionClick(psp.name) }>
              { psp.name }
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  };

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);

    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  addNewColumn(columns) {
    // eslint-disable-next-line eqeqeq
    if (columns.find((elem) => elem.label === "Edit")) {
      return columns;
    }
    columns.push({
      value: "id",
      label: "Edit",
      centerAligned: true,
      className: "btn edit-client",
      route: "/edit-provider",
      isAction: true
    });
    return columns;
  };

  onCompanyChange = (event) => {
    const { controls } = this.state;
    this.setState({
      controls: Object.assign(controls, {
        company: event.value,
      }),
    });
  };

  onUpdateClick = () => {
    const { controls } = this.state;
    if (controls.company && controls.isFormValid()) {
      this.setState({
        selectedCompany: controls.company,
        showProviders: true,
        editableStatus: false
      }, () => {
        const { columns, selectedCompany } = this.state;

        Promise.all([
          crudActions.get(`v1/psp?companyId=${selectedCompany}&statuses=${controls.pspStatus}`),
          crudActions.get(`v1/companies/provider-toggle/${selectedCompany}`)
        ])
          .then((data) => {
            const psps = data[0];
            const toggleInfo = data[1];

            this.setState({
              showProviders: true,
              psps
            });

            const isStatusColumnPresent = columns.some((elem) => elem.value === "enabled");
            const isPayoutStatusColumnPresent = columns.some((elem) => elem.value === "enabled");
            if (toggleInfo.providerOnOffToggleVisible && this.checkPageAccess("PSP_ON_OFF")) {
              if (!isPayoutStatusColumnPresent) {
                columns.splice(4, 0, { value: "enabledPayout", label: "Payout Status" });
              }
              if (!isStatusColumnPresent) {
                columns.splice(4, 0, { value: "enabled", label: "Payin Status" });
              }
            } else {
              if (isStatusColumnPresent) {
                columns.splice(4, 1);
              }
              if (isPayoutStatusColumnPresent) {
                columns.splice(4, 1);
              }
            }
          });
      });
    }
  };

  onTogglerClick = (option, providerId, value, field) => {
    const { pspStatusLoader, selectedCompany } = this.state;
    const isPayin = field === 'currencyAndMethods';
    if(pspStatusLoader.some((item) => item.psId === providerId && item.currency === option.currency && item.method === option.method && item.isPayin === isPayin)) {
      return;
    }

    if (providerId) {
      this.setState({
        pspStatusLoader: pspStatusLoader.concat({ psId : providerId, currency : option.currency, method: option.method, isPayin: isPayin })
      });

      crudActions.patch(`v1/psp`, {
        enabled: value,
        id: providerId,
        companyId: selectedCompany,
        currency: option.currency,
        method: option.method,
        isPayin: isPayin
      }).then(() => {
        const { psps } = this.state;
        const currentPsp = psps.find(psp => psp.id === providerId);
        const defaultValue = currentPsp[field].find((current) => current.currency === option.currency && current.method === 'DEFAULT');
        currentPsp[field] = currentPsp[field].map((currencyAndMethod) => {
          return currencyAndMethod.currency === option.currency && (currencyAndMethod.method === option.method || option.method === 'DEFAULT')
            ? { ...currencyAndMethod, enabled: option.method !== 'DEFAULT' ? !currencyAndMethod.enabled : !defaultValue.enabled }
            : currencyAndMethod;
        });
        this.setState({
          psps,
          pspStatusLoader: this.state.pspStatusLoader.filter(status => status.psId !== providerId && status.currency !== option.currency && status.method !== option.method && status.isPayin === isPayin),
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              pspStatusLoader: this.state.pspStatusLoader.filter(status => status.psId !== providerId && status.currency !== option.currency && status.method !== option.method && status.isPayin === isPayin)
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  handleClickOutside = (event) => {
    if (this.state.openDropdown && this.state.openDropdown.id && !event.target.closest('.dropdown')) {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ openDropdown: null });
  };

  toggleDropdown = (providerId, isPayin) => {
    const { openDropdown } = this.state;

    if (!openDropdown || openDropdown.id !== providerId) {
      this.setState({
        openDropdown: { id: providerId, isPayin },
      });
      return;
    }

    if (openDropdown.isPayin === isPayin) {
      this.closeDropdown();
      return;
    }

    this.setState({
      openDropdown: { id: providerId, isPayin },
    });
  };

  mapStatus = () => {
    const { availablePspStatuses, controls } = this.state;
    return controls.pspStatus.map(elem => {
      return availablePspStatuses.find(lookupElem => elem === lookupElem.value);
    });
  };

  onStatusChange = (event) => {
    const { controls } = this.state;
    this.setState({
      controls: Object.assign(controls, {
        pspStatus: event.map(elem => elem.value),
      }),
    });
    controls.isFormValid();
  };

  handleConfirmPromptModal = () => {
    const { newStatus, selectedCompany, editablePspId, psps } = this.state;
    const data = {
      "id": editablePspId,
      "active": newStatus,
      "companyId": selectedCompany
    };
    crudActions.patch(
      `v1/psp/active`,
      data
    ).then( () => {
      const currentPsp = psps.find(psp => psp.id === editablePspId);
      currentPsp.active = newStatus;
      this.setState({
        showPromptModal: false,
        changeValueMessage: "",
        editablePspId: "",
        alertType: "success",
        alertMessage: "Status has been updated!",
        editableStatus: false,
        psps
      });
    }).catch(
      err => {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: `Couldn't update status.`,
          showPromptModal: false,
          changeValueMessage: "",
          editablePspId: "",
        });
      }
    );

  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  render() {
    const {
      alertMessage,
      alertType,
      availablePspStatuses,
      columns,
      companies,
      searchPattern,
      showAlert,
      showProviders,
      showPromptModal,
      controls
    } = this.state;

    if (this.checkPageAccess("PSP_EDIT")) {
      this.addNewColumn(columns);
    }
    return (
      <Row flexGrow={ 1 } className="module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ PSPIconSingle } alt="" style={ { marginRight: 10 } } />
                Providers
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                  <Column horizontal='end' style={ { paddingRight: 15 } }>
                    <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                      <NavLink to={ `/add-provider` } className="btn add-merchant-button">
                        ADD PROVIDER
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                      SEARCH PROVIDERS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            name="company"
                            value={ controls.company || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onCompanyChange(value) }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Status </label>
                          <Multiselect
                            isError={ controls.errors.has("pspStatus") }
                            selectedItems={ this.mapStatus() }
                            items={ availablePspStatuses }
                            type={ "pspStatus" }
                            onChange={ this.onStatusChange } />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <CustomButton
                            title="Update"
                            type="submit"
                            onClick={ () => this.onUpdateClick() }
                          />
                        </Column>
                      </Row>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>
              
              { showProviders && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      PROVIDERS INFORMATION
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                        <input
                          className='form-control'
                          type='text'
                          value={ searchPattern }
                          placeholder="Search Provider"
                          onChange={ this.handleSearchChange }
                        />
                        { this.isBoxOpen() }
                      </Row>

                      <div className="clients-wrapper">
                        <Table
                          columns={ columns }
                          data={ this.searchSuggests() }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              )}

            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}
        <PromptModal
          title="Change status"
          message={ this.state.changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ this.state.changeActivePropertyLoading }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default PSPs;
