import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Button, Panel } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { saveAs } from 'file-saver';
import Pagination from '@mui/material/Pagination';

import Alert from '@sm/components/custom/Alert';
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Dropdowns from '@modules/apiData/dropdowns';
import Form from "@sm/core/Form";
import Modal from '@sm/components/custom/Modal';
import Multiselect from "@sm/components/custom/customMultiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import "react-datepicker/dist/react-datepicker.css";
import "@assets/css/generateReports.css";
import '@assets/css/apiData.css';
import ReportIcon from '@assets/images/report.png';
import Calendar from '@assets/images/calendar.png';
import LoadingIcon from '@assets/images/loading.png';

import { crudActions } from "@sm/services/crudActions";
import { addLogItem, getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "@sm/utils/utils";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
import { SEARCH_TRANSACTIONS_REGEX } from "@sm/constants";
import ExpandAllIcon from '@assets/images/expand-all-icon.png';
import CollapseAllIcon from '@assets/images/collapse-all-icon.png';

const INTEGER_MAX = 2147483647;

const store = require('@sm/reducers/index');

class GenerateReport extends Component {
  state = {
    controls: {
      timePeriod: {
        value: 'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    expandAll: false,
    periods: [],
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
    companies: [],
    companiesAccounts: {},
    companiesClients: {},
    companiesPsps: {},
    companiesClientsMids: {},
    companiesClientsPayoutMids: {},
    companiesDefaultTimezones: {},
    companiesTimezoneTypes: {},
    depositMethod: [],
    depositStatus: [],
    depositPayoutStatus: [],
    sortBy: {},
    sortByDate: [],
    reportType: [],
    countries: [],
    properties: {},
    payoutMethod: [],
    payoutCountry: [],
    payoutStatus: [],
    settlementStatuses: [],
    currency: [],
    baseCurrencies: [],
    displayMode: [],
    timeZones: [],
    timeStampList: [],
    agents: [],
    reportFormRules: [{
      name: "clientIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "reportType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeStampList",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "displayMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "sortByDate",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currency",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositMethod",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositStatus",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      value: "",
      rules: {
        trim: true,
        required: false,
        pattern: SEARCH_TRANSACTIONS_REGEX
      }
    }],
    reportFormData: {
      clientIds: [],
      depositMethod: [],
      depositStatus: [],
      currency: [],
      sortBy: "",
      reportType: "",
      displayMode: "",
      transactionId: "",
      sortByDate: "DAY",
      midIds: [],
      timeZone: "",
      timeStampList: null
    },
    reportForm: {},

    reportsGenerated: {},
    savedGeneratedReport: {},

    selectedCompany: "",
    savedSelectedCompany: "",

    endpointGenerateMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/list"
      },
      PAYOUT: {
        LIST: "v1/reports/payout"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list"
      },
      RISK_AND_SECURITY: {
        LIST: "v1/reports/list/fraud"
      },
      FRAUD_CHECK: {
        LIST: "v1/reports/list/fraud-check"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/deposits_payouts"
      },
      PAY_COMMISSIONS: {
        LIST: "v2/reports/master/settle-agent"
      },
      CONVERSION_RATE: {
        LIST: "v1/reports/conversion/rate"
      },
      AGENTS_COMMISSIONS: {
        LIST: "v2/reports/master/agent-commissions"
      },
      INCOMING_FUNDS: {
        LIST: "v2/reports/master/incoming-funds"
      }
    },

    defaultTimePeriods: {
      GROSS_PROFIT: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SERVER_LOGS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      TRANSACTIONS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      PAYOUT: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      RUNNING_BALANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SETTLEMENTS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      RISK_AND_SECURITY: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      FRAUD_CHECK: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      PERFORMANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      DEPOSITS_PAYOUTS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      PAY_COMMISSIONS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      CONVERSION_RATE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      AGENTS_COMMISSIONS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      INCOMING_FUNDS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      }
    },

    endpointXlsxMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list/xlsx",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed/xlsx"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions/xlsx"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/xlsx"
      },
      PAYOUT: {
        LIST: "v1/reports/payout/xlsx"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list/xlsx"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list/xlsx"
      },
      RISK_AND_SECURITY: {
        LIST: "v1/reports/list/fraud/xlsx"
      },
      FRAUD_CHECK: {
        LIST: "v1/reports/list/fraud-check/xlsx"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance/xlsx"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/deposits_payouts/xlsx"
      },
      PAY_COMMISSIONS: {
        LIST: "v2/reports/master/settle-agent/xlsx"
      },
      CONVERSION_RATE: {
        LIST: "v1/reports/conversion/rate/xlsx"
      },
      AGENTS_COMMISSIONS: {
        LIST: "v2/reports/master/agent-commissions/xlsx"
      },
      INCOMING_FUNDS: {
        LIST: "v2/reports/master/incoming-funds/xlsx"
      }
    },

    trafficTypes: [{
      value: "FTD",
      label: "FTD"
    }, {
      value: "MIXED",
      label: "Mixed"
    }, {
      value: "TRUSTED",
      label: "Trusted"
    }],

    modalData: "{}",
    modalType: "json",
    modalTitle: "",

    isLoading: false,
    isLoadingLookups: true,
    showAlert: false,
    alertType: "error",
    alertMessage: "",
    enableButton: false,

    loadingRBReportId: "",
    rbIntervalID: null,
    access: [],
    blockLoader: [],
    isButtonLoading: "",
    loadingButtonType: "",
    roleId: "",
    isSortSettingsOpen: false,
    isExportSettingsOpen: false,
    isReportErrorFields: false,
    editableTransactionId: "",
    editableTransactionValue: "",
    editableTransactionField: "",
    showModal: false,
    isModalLoading: false,
    showPromptModal: false,
    changeTXPropertyLoading: false,
    changeValueMessage: "",
    currentPage: 1,
    rowsAmount: 100,
    clickButton: false,
    cnt: 0,
    pageType: "reports",
    periodId: 0 //today
  };

  sortNode = null;
  exportNode = null;
  thisMonth = true;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
    document.addEventListener("keydown", this.handleEscapeTransactionStatus, false);
    const { reportFormRules, reportFormData } = this.state;
    const { match } = this.props;

    // Manually add custom periods
    const updatedPeriods = [...Dropdowns.periods];
    updatedPeriods.splice(6, 0, { id: 10, value: 'last15Mins', label:'Last 15 Minutes'}, { id: 9, value: 'last30Mins', label:'Last 30 Minutes'});

    this.setState({
      reportForm: new Form(reportFormData, reportFormRules),
      periods: updatedPeriods,
      loading: false,
      pageType: match && match.path === "/transactions" ? "transactions" : "reports"
    });
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      (storeState.roleId !== "PROVIDER_ADMIN" ? crudActions.get('v1/agent/all') : Promise.resolve()),
      crudActions.get(`v1/companies/clients`)
    ]).then((data) => {
      const agents = data[0];
      const companiesData = data[1];

      this.setState({
        agents
      });

      if (companiesData) {
        const companies = companiesData.map(elem => elem.company);
        companies.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
        this.setState({
          companies: companies,
          companiesAccounts: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.accounts.map(elem => elem);
            return accumulator;
          }, {}),
          companiesClients: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
            return accumulator;
          }, {}),
          companiesPsps: companiesData.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.company.value] = currentElem.psps.map(elem => elem);
              return accumulator;
            }, {}),
          companiesClientsMids: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.clients.reduce((accum, curElem) => {
              accum[curElem.client.value] = curElem.mids;
              return accum;
            }, {});
            return accumulator;
          }, {}),
          companiesClientsPayoutMids: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.clients.reduce((accum, curElem) => {
              accum[curElem.client.value] = curElem.payoutMids;
              return accum;
            }, {});
            return accumulator;
          }, {}),
          companiesDefaultTimezones: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.defaultTimezone;
            return accumulator;
          }, {}),
          companiesTimezoneTypes: companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.timeZoneType;
            return accumulator;
          }, {}),
        }, () => {
          companies.length && this.onSelectCompanyChange(companies[0]);
        });
      }
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
    document.removeEventListener("keydown", this.handleEscapeTransactionStatus, false);
    clearInterval(this.state.rbIntervalID);
  };

  handleClickOutside = (event) => {
    const { isSortSettingsOpen, isExportSettingsOpen } = this.state;

    if (!isSortSettingsOpen && !isExportSettingsOpen) return;

    if (this.sortNode && this.sortNode.contains(event.target)) {
      this.setState({
        isExportSettingsOpen: false
      });

      return;
    }

    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false,
      isSortSettingsOpen: false,
      showAlert: false,
      alertType: "error",
      alertMessage: ""
    });
  };

  handleEscapeTransactionStatus = (event) => {
    if (event.keyCode === 27 && this.state.editableTransactionId !== "") {
      this.setState({
        editableTransactionId: "",
        editableTransactionValue: ""
      });
    }
  };

  getFields = () => {
    const { reportForm, sortBy, currency, payoutStatus, depositMethod, depositStatus,
      depositPayoutStatus, properties, selectedCompany, settlementStatuses, trafficTypes } = this.state;

    const displayMode = reportForm.displayMode;
    const isSortByVisible = reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "RUNNING_BALANCE" || reportForm.reportType === "CONVERSION_RATE";

    const baseFields = [
      isSortByVisible ? {
        id: "sortBy",
        type: "select",
        label: "Sort By",
        disabled: !reportForm.reportType || !selectedCompany,
        options: reportForm.reportType ? sortBy[reportForm.reportType] : []
      } : {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }
    ];

    const buttonField = [{
      id: "button",
      type: "button"
    }];

    const manuallyUpdated = [{ value: "updated", label: "Manually updated" }, { value: "notUpdated", label: "Not updated" }];

    const reportFields = {
      TRANSACTIONS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {
          id: "trafficTypes",
          type: "multiselect",
          label: "Traffic",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("trafficTypes", "trafficTypes"),
          items: trafficTypes
        }],
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, false, true)
        }, {
          id: "updateStatus",
          type: "multiselect",
          label: "Update status",
          selectedItems: manuallyUpdated,
          items: manuallyUpdated
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: !selectedCompany
        }, {}],
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      GROSS_PROFIT: [
        [{
          id: "properties",
          type: "multiselect",
          label: "Properties",
          notShowOnCondition: displayMode !== "LIST_DETAILED",
          selectedItems: this.mapSelectedItems("properties", "properties", true),
          items: reportForm.reportType ? properties[reportForm.reportType] : []
        }, {}, {}, {}],
        [{}, {}, {
          id: "removeZeroValues",
          type: "checkbox",
          label: "Remove Zero Values"
        }]
      ],
      SERVER_LOGS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: !selectedCompany
        }],
        [{}, {}, {}]
      ],
      PAYOUT: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, false, false, false)
        }, {
          id: "payoutStatus",
          type: "multiselect",
          label: "Payout Status",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("payoutStatus", "payoutStatus"),
          items: payoutStatus
        }, {
          id: "updateStatus",
          type: "multiselect",
          label: "Update status",
          selectedItems: manuallyUpdated,
          items: manuallyUpdated
        }],
        [{
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: !selectedCompany
        }, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      RUNNING_BALANCE: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "accountIds",
          type: "multiselect",
          label: "Accounts",
          selectedItems: this.mapSelectedItems("accountIds", "accountIds"),
          items: this.getAccounts()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, true, false)
        }, {}],
        [{
          id: "hideEmptyRows",
          type: "checkbox",
          label: "Hide Empty Rows"
        }, {
          id: "hideEmptyColumns",
          type: "checkbox",
          label: "Hide Empty Columns"
        }, {}]
      ],
      SETTLEMENTS: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, false, false)
        }, {}],
        [{
          id: "settlementStatus",
          type: "multiselect",
          label: "Statuses",
          selectedItems: this.mapSelectedItems("settlementStatus", "settlementStatuses"),
          items: settlementStatuses
        }, {
          id: "invoiceNumber",
          type: "input",
          label: "Search Invoice #",
          disabled: !selectedCompany
        }, {}]
      ],
      RISK_AND_SECURITY: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: !selectedCompany
        }, {}],
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      FRAUD_CHECK: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "currency",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, false, false)
        }, {
          id: "updateStatus",
          type: "multiselect",
          label: "Update status",
          selectedItems: manuallyUpdated,
          items: manuallyUpdated
        }],
        [{
          id: "depositMethod",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: !selectedCompany
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      PERFORMANCE: [
        [{
          id: "email",
          type: "textarea",
          label: "Search Email",
          disabled: !selectedCompany
        }, {
          id: "ip",
          type: "textarea",
          label: "Search IP Address",
          disabled: !selectedCompany
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      DEPOSITS_PAYOUTS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: !selectedCompany,
          label: "Methods",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositPayoutStatus
        }, {
          id: "trafficTypes",
          type: "multiselect",
          label: "Traffic",
          disabled: !selectedCompany,
          selectedItems: this.mapSelectedItems("trafficTypes", "trafficTypes"),
          items: trafficTypes
        }],
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, false, true)
        }, {
          id: "updateStatus",
          type: "multiselect",
          label: "Update status",
          selectedItems: manuallyUpdated,
          items: manuallyUpdated
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search",
          disabled: !selectedCompany
        }], [
          {}, {}, {
            id: "bulkButton",
            type: "bulkButton"
          }
        ]
      ],
      PAY_COMMISSIONS: [
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      CONVERSION_RATE: [
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      AGENTS_COMMISSIONS: [
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      INCOMING_FUNDS: [
        [{
          id: "pspIds",
          type: "select",
          label: "Provider",
          disabled: false,
          options: this.getProviders()
        }, {
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "accountIds",
          type: "multiselect",
          label: "Accounts",
          withId: true,
          selectedItems: this.mapSelectedItems("accountIds", "accountIds"),
          items: this.getAccounts()
        }, {}],
        [{
          id: "settlementStatus",
          type: "multiselect",
          label: "Statuses",
          selectedItems: this.mapSelectedItems("settlementStatus", "settlementStatuses"),
          items: settlementStatuses
        }, {
          id: "invoiceNumber",
          type: "input",
          label: "Search Invoice #",
          disabled: !selectedCompany
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }
        ],
      ]
    };

    const buildFields = (type) => {
      const fields = reportFields[type];
      if (type === "RUNNING_BALANCE") {
        baseFields[1] = {
          id: "currency",
          type: "select",
          label: "Currency",
          disabled: false,
          options: this.getRunningBalanceCurrencies()
        };
      }
      if (type === "CONVERSION_RATE") {
        baseFields[1] = {
          id: "currency",
          type: "select",
          disabled: !selectedCompany,
          label: "Currency",
          options: currency
        };
        baseFields[2] = {
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        };
        baseFields[3] = {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, true, false, false)
        };
      }
      fields.splice(0, 0, baseFields);
      fields[fields.length - 1] = fields[fields.length - 1].concat(buttonField);
      return fields;
    };

    const selectedReportFields = reportFields[reportForm.reportType];

    if (!selectedReportFields) {
      return buildFields('TRANSACTIONS');
    }

    return buildFields(reportForm.reportType);
  };

  defineReportForm = (reportType) => {
    const { reportForm, reportFormRules, reportFormData, companiesClients, selectedCompany, timeStampList } = this.state;

    const clients = companiesClients[selectedCompany];
    let selectedClient = "";
    if (clients && clients.length) {
      selectedClient = clients[0].value;
    }

    if (!clients.find(client => reportForm["clientIds"] && reportForm["clientIds"].includes(client.value))) {
      reportForm.clientIds = null;
    }

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const payoutForm = new Form({
      clientIds: reportForm.clientIds || [selectedClient],
      payoutStatus: [],
      currency: [],
      sortBy: "",
      reportType: reportType,
      displayMode: "",
      pspIds: [],
      midIds: [],
      sortByDate: reportForm.sortByDate,
      timeStampList: timeStampList,
      timeZone: "",
      transactionId: "",
      updateStatus: []
    }, copyArray(reportFormRules.slice(0, 5)).concat([{
      name: "pspIds",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "midIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "payoutStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      rules: {
        trim: true,
        required: false,
        pattern: SEARCH_TRANSACTIONS_REGEX
      }
    }]));

    const grossProfitForm = new Form({
      clientIds: reportForm.clientIds || [selectedClient],
      reportType: reportType,
      displayMode: "",
      sortBy: "",
      sortByDate: reportForm.sortByDate,
      grossCurrency: "",
      removeZeroValues: false
    }, copyArray(reportFormRules.slice(0, 4)).concat(
      [{
        name: "grossCurrency",
        type: "isString",
        rules: {
          required: true
        }
      }]
    ));

    const runningBalanceForm = new Form(
      {
        clientIds: reportForm.clientIds || [selectedClient],
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        pspIds: [],
        accountIds: [],
        midIds: [],
        removeZeroValues: false,
        sortByDate: "DAY",
        currency: [],
        hideEmptyRows: true,
        hideEmptyColumns: true
      }, copyArray(reportFormRules.slice(0, 5)).concat(
        [{
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "accountIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            required: true
          }
        }]
      )
    );

    const settlementsForm = new Form(
      {
        clientIds: reportForm.clientIds || [selectedClient],
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        pspIds: [],
        midIds: [],
        currency : [],
        settlementStatus: [],
        invoiceNumber: "",
        removeZeroValues: false,
        sortByDate: "DAY"
      }, copyArray(reportFormRules.slice(0, 5)).filter(elem => elem.name !== "timeZone").concat(
        [{
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
            extendValue: "MIDS"
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
            extendValue: "CURRENCY"

          }
        }, {
          name: "settlementStatus",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "invoiceNumber",
          type: "isPattern",
          rules: {
            required: false,
            pattern: "^[0-9]+$"
          }
        }, {
          name: "timeZone",
          type: "isString",
          rules: {
            required: false
          }
        }]
      )
    );

    const incomingFundsForm = new Form(
      {
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        pspIds: [],
        accountIds: [],
        currency : [],
        settlementStatus: [],
        invoiceNumber: "",
        removeZeroValues: false,
        sortByDate: "DAY"
      }, copyArray(reportFormRules.slice(0, 5)).filter(elem => elem.name !== "timeZone").concat(
        [{
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "accountIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "settlementStatus",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "invoiceNumber",
          type: "isPattern",
          rules: {
            required: false,
            pattern: "^[0-9]+$"
          }
        }, {
          name: "timeZone",
          type: "isString",
          rules: {
            required: false
          }
        }]
      )
    );

    const transactionsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        pspIds: reportForm.pspIds || [],
        midIds: reportForm.midIds || [],
        trafficTypes: [],
        timeZone: "",
        timeStampList: timeStampList,
        updateStatus: []
      }),
      copyArray(reportFormRules).concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "trafficTypes",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }
      )
    );

    const serverLogsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        sortBy: "",
        sortByDate: "DAY"
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus" && elem.name !== "timeZone").concat(
        {
          name: "timeZone",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const riskSecurityForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        sortBy: "",
        sortByDate: "DAY"
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus" && elem.name !== "timeZone").concat(
        {
          name: "timeZone",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const fraudCheckForm = new Form(
      Object.assign({}, reportFormData.filter, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        pspIds: [],
        midIds: [],
        sortBy: "",
        sortByDate: "DAY",
        timeStampList: timeStampList,
        depositMethod: [],
        transactionId: "",
        updateStatus: []
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus" && elem.name !== "timeZone").concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "timeZone",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const performanceForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        timeZone: "",
        timeStampList: timeStampList,
        email: "",
        ip: "",
        sortBy: "",
        sortByDate: "DAY"
      }),
      copyArray(reportFormRules).concat(
        {
          name: "email",
          type: "isString",
          rules: {
            required: false
          }
        },
        {
          name: "ip",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const depositsPayoutsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        sortBy: "",
        sortByDate: "DAY",
        timeZone: "",
        timeStampList: timeStampList,
        pspIds: reportForm.pspIds || [],
        midIds: reportForm.midIds || [],
        trafficTypes: [],
        updateStatus: []
      }),
      copyArray(reportFormRules).filter(elem => elem.name !== "transactionId").concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "trafficTypes",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "transactionId",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const payCommissionsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        agentIds: [],
        sortByDate: "DAY"
      }),
      copyArray(reportFormRules.slice(0, 5)).filter(elem => elem.name !== "timeZone" && elem.name !== "sortBy" && elem.name !== "timeStampList").concat(
        {
          name: "agentIds",
          type: "isArray",
          rules: {
            min: 1,
            required: false
          }
        }
      )
    );

    const conversionRateForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        pspIds: reportForm.pspIds || [],
        midIds: reportForm.midIds || [],
        timeZone: ""
      }),
      copyArray(reportFormRules.slice(0, 5)).filter(elem => elem.name !== "timeStampList").concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }
      )
    );

    const agentCommissionForm = new Form(
      Object.assign({}, reportFormData, {
        reportType: reportType
      }),
      []
    );

    if (reportType === "GROSS_PROFIT") {
      return grossProfitForm;
    } else if (reportType === "PAYOUT" ) {
      return payoutForm;
    } else if (reportType === "RUNNING_BALANCE" ) {
      return runningBalanceForm;
    } else if (reportType === "SETTLEMENTS" ) {
      return settlementsForm;
    } else if (reportType === "RISK_AND_SECURITY" ) {
      return riskSecurityForm;
    } else if (reportType === "FRAUD_CHECK" ) {
      return fraudCheckForm;
    } else if (reportType === "SERVER_LOGS") {
      return serverLogsForm;
    } else  if (reportType === "PERFORMANCE") {
      return performanceForm;
    } else if (reportType === "DEPOSITS_PAYOUTS") {
      return depositsPayoutsForm;
    } else if (reportType === "PAY_COMMISSIONS") {
      return payCommissionsForm;
    } else if (reportType === "CONVERSION_RATE") {
      return conversionRateForm;
    } else if (reportType === "AGENTS_COMMISSIONS") {
      return agentCommissionForm;
    } else if (reportType === "INCOMING_FUNDS" ) {
      return incomingFundsForm;
    } else {
      return transactionsForm;
    }
  };

  preselectReportFields = (reportType) => {
    const { sortBy, currency, depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses, defaultTimePeriods,
      baseCurrencies, payoutStatus, timeZones, timeStampList, companiesClients, companiesPsps, selectedCompany, pageType,
      trafficTypes, agents, companiesDefaultTimezones, companiesTimezoneTypes } = this.state;

    const merchants = selectedCompany ? companiesClients[selectedCompany] : [];
    const providers = selectedCompany ? companiesPsps[selectedCompany] : [];
    const displayMode = this.filterDisplayMode();
    const firstSortBy = sortBy[reportType] && sortBy[reportType][0];

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "RISK_AND_SECURITY" ||
      reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositMethod, "depositMethod");
    }

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE") {
      this.onValueChange(depositStatus, "depositStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(trafficTypes, "trafficTypes");
    }

    if (reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositPayoutStatus, "depositStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "FRAUD_CHECK" || reportType === "CONVERSION_RATE") {
      if (reportType !== "FRAUD_CHECK") {
        const defaultTimezone = selectedCompany ? companiesDefaultTimezones[selectedCompany] : "";
        if (defaultTimezone && companiesTimezoneTypes[selectedCompany] !== "TZ_V2") {
          this.onValueChange(timeZones.find(t => t.value === defaultTimezone), "timeZone");
        } else {
          this.onValueChange(timeZones[0], "timeZone");
        }
      }
      if (reportType !== "CONVERSION_RATE") {
        this.onValueChange(timeStampList[1], "timeStampList");
      } else {
        this.onValueChange(timeStampList[0], "timeStampList");
      }
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "SERVER_LOGS" ||
      reportType === "RISK_AND_SECURITY" || reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
      reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(currency, "currency");
    }

    if (reportType === "RISK_AND_SECURITY" || reportType === "SERVER_LOGS") {
      this.onValueChange(merchants[0], "clientIds");
    }

    if (reportType === "PAYOUT") {
      this.onValueChange(payoutStatus, "payoutStatus");
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "GROSS_PROFIT") {
      this.onValueChange(baseCurrencies[0], "grossCurrency");
      this.onValueChange(properties[reportType], "properties");
    }

    if (reportType === "SETTLEMENTS" || reportType === "INCOMING_FUNDS") {
      this.onValueChange(settlementStatuses, "settlementStatus");
    }

    if (reportType === "PAY_COMMISSIONS") {
      const filteredAgents = agents.filter(a => a.companyId.toString() === selectedCompany);
      this.onValueChange(filteredAgents, "agentIds");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "RUNNING_BALANCE" ||
      reportType === "SETTLEMENTS" || reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
      reportType === "DEPOSITS_PAYOUTS" || reportType === "CONVERSION_RATE" || reportType === "INCOMING_FUNDS") {
      if (reportType !== "INCOMING_FUNDS") {
        this.onValueChange(merchants, "clientIds");
      }
      this.onValueChange(reportType === "INCOMING_FUNDS" && providers[0] ? providers[0] : providers, "pspIds");
      if (reportType === "SETTLEMENTS" || reportType === "INCOMING_FUNDS") {
        this.onValueChange(currency, "currency");
      }
      if (reportType === "RUNNING_BALANCE" || reportType === "CONVERSION_RATE") {
        const currencies = reportType === "RUNNING_BALANCE" ? this.getRunningBalanceCurrencies() : currency;
        this.onValueChange(currencies[0], "currency");
        const accounts = this.getAccounts();
        this.onValueChange(accounts, "accountIds");
      }
      if ( reportType === "INCOMING_FUNDS") {
        const accounts = this.getAccounts();
        this.onValueChange(accounts, "accountIds");
      }
      const mids = this.getMidsByProvidersAndCurrencies(
        reportType === "PAYOUT",
        reportType !== "PAYOUT" && reportType !== "PERFORMANCE",
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
      this.onValueChange(mids, "midIds");
    }

    this.onValueChange(displayMode[0], "displayMode");
    if (reportType === "RUNNING_BALANCE") {
      const sortByDate = sortBy[reportType] && sortBy[reportType].find(e => e.value === "DATE");
      this.onValueChange(sortByDate, "sortBy");
    } else {
      this.onValueChange(firstSortBy, "sortBy");
    }
    this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");

    if (pageType === "transactions") {
      this.onGenerateReport();
    }
  };

  getPeriod(periodType) {
    let timeZoneOffset = "00:00";
    if (this.state) {
      const { companiesTimezoneTypes, reportForm, selectedCompany, timeZones } = this.state;
      const selectedCompanyTimezoneType = companiesTimezoneTypes[selectedCompany];
      if (reportForm.timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
        const searchTimeZone = timeZones.find(zone => zone.value === reportForm.timeZone);
        if (searchTimeZone) {
          timeZoneOffset = searchTimeZone.offset;
        }
      }
    }

    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 3: // last month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1)
          .date(moment().utc().utcOffset(timeZoneOffset).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 5: // last year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 6: // last hour
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'hours'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(6, 'hours'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 9: // last 30 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(30, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 10: // last 15 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(15, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    }
  };

  getFilteredPeriods = (reportType) => {
    const { periods } = this.state;

    if (reportType === "RISK_AND_SECURITY" || reportType === "RUNNING_BALANCE" || reportType === "SERVER_LOGS" || reportType === "SETTLEMENTS") {
      return periods.filter(period => period.id !== 9 && period.id !== 10);
    }
    return periods;
  };

  onSelectCompanyChange = (event) => {
    const companyId = event.value;
    const { reportForm, pageType } = this.state;

    if ( reportForm.reportType === "RUNNING_BALANCE" ) {
      reportForm.midIds = [];
    }
    this.setState({
      selectedCompany: companyId,
      currentPage: 1
    }, () => {
      Promise.all([
        crudActions.get(`v1/reports/company/${companyId}/lookups`)
      ]).then(
        (data) => {
          let lookups = data[0];
          if (lookups) {
            // filter reports on Transactions page
            if (pageType === "transactions") {
              lookups = Object.assign(lookups, {
                reportType: lookups.reportType.filter(item => item.value === "DEPOSITS_PAYOUTS")
              });
            }
            const filtered = Object.assign(lookups, {
              reportType: lookups.reportType,
              sortBy: Object.assign(lookups.sortBy, {
                PERFORMANCE: [{
                  value: "EMAIL",
                  label: "Email"
                }, {
                  value: "IP",
                  label: "IP Address"
                }, {
                  value: "MERCHANT",
                  label: "Merchant"
                }, {
                  value: "PROVIDER",
                  label: "Provider"
                }],
                "INCOMING_FUNDS": [{
                  value: "DATE",
                  label: "Date"
                }]
              }),
              depositPayoutStatus: this.unionBy([lookups.depositStatus, lookups.payoutStatus], "value")
            });
            const reportType = lookups.reportType.find(report => report.value === "TRANSACTIONS") || lookups.reportType[0];
            this.setState({
              ...filtered,
              isLoadingLookups: false
            }, () => {
              this.onValueChange(reportType, "reportType");
            });
          }
        }
      );
    });
  };

  unionBy = (arrays, iteratee) => {
    const map = {};

    arrays.forEach((array) => {
      array.forEach((object) => {
        map[object[iteratee]] = object;
      });
    });

    return Object.values(map);
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = { ...this.state.timeStamp };
    const controls = { ...this.state.controls };
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = (value.id > 0 && value.id <= 7) || value.id >= 9; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      currentPage: 1,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = { ...this.state.controls };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp, isSubmitting: false });
  };

  isDateValid = (current, state) => {
    const { companiesTimezoneTypes, reportForm, selectedCompany, timeZones } = this.state;
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;
    let timeZoneOffset = "00:00";
    const selectedCompanyTimezoneType = companiesTimezoneTypes[selectedCompany];
    if (reportForm.timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }
    const yesterday = Datetime.moment().utc().utcOffset(timeZoneOffset).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  onValueChange = (newValue, fieldName) => {
    let { reportForm } = this.state;
    const { isReportErrorFields, defaultTimePeriods } = this.state;
    
    if (newValue !== undefined) {
      if (typeof newValue === "boolean") {
        reportForm = Object.assign(reportForm, {
          [fieldName]: !newValue
        });
      } else if (newValue.value) {
        reportForm = Object.assign(reportForm, {
          [fieldName]: fieldName === "clientIds" || fieldName === "pspIds" || fieldName === "currency" ? [newValue.value] : newValue.value
        });
      } else if (newValue.target) {
        reportForm = Object.assign(reportForm, {
          [fieldName]: newValue.target.value
        });
      } else {
        reportForm = Object.assign(reportForm, {
          [fieldName]: newValue.map(elem => elem.value)
        });
      }
    }

    this.setState({
      currentPage: 1
    });

    const reportType = reportForm.reportType;

    if ((fieldName === "currency" || fieldName === "accountIds") && (reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS" || reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "CONVERSION_RATE")) {
      const mids = this.getMidsByProvidersAndCurrencies(
        false,
        true,
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
      reportForm.midIds = mids.map(mid => mid.value);
    } else if (fieldName === "clientIds" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "SETTLEMENTS" || reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "CONVERSION_RATE")) {
      const providers = this.getProviders();
      reportForm.pspIds = providers.map(psp => psp.value);
      if (reportType === "RUNNING_BALANCE" || reportType === "CONVERSION_RATE") {
        const currencies = this.getRunningBalanceCurrencies();
        if (currencies && currencies.length) {
          reportForm.currency = [currencies[0].value];
        }
      }
      const mids = this.getMidsByProvidersAndCurrencies(
        reportType === "PAYOUT",
        reportType !== "PAYOUT" && reportType !== "PERFORMANCE",
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
      reportForm.midIds = mids.map(mid => mid.value);
    } else if (fieldName === "pspIds") {
      if (reportType === "RUNNING_BALANCE") {
        const accounts = this.getAccounts();
        reportForm.accountIds = accounts.map(acc => acc.value);
      }
      const mids = this.getMidsByProvidersAndCurrencies(
        reportType === "PAYOUT",
        reportType !== "PAYOUT" && reportType !== "PERFORMANCE",
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
      reportForm.midIds = mids.map(mid => mid.value);
    } else if ( reportType === "SERVER_LOGS") {
      delete reportForm.midIds;
      delete reportForm.timeStampList;
      delete reportForm.timeZone;
    } else if (fieldName === "timeZone" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS" || reportType === "PERFORMANCE" || reportType === "CONVERSION_RATE")) {
      const mids = this.getMidsByProvidersAndCurrencies(
        reportType === "PAYOUT",
        reportType !== "PAYOUT" && reportType !== "PERFORMANCE",
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
      reportForm.midIds = mids.map(mid => mid.value);
      const currentPeriod = this.state.periods.find(elem => elem.id === this.state.controls.timePeriod.id);
      this.onSelectPeriodChange(currentPeriod, "timePeriod");
    } else if (fieldName === "trafficTypes" && (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS")) {
      const mids = this.getMidsByProvidersAndCurrencies(false, true, false, true);
      reportForm.midIds = mids.map(mid => mid.value);
    }

    const isReportType = fieldName === "reportType";
    if (isReportType) {
      this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
    }

    if (isReportErrorFields) {
      reportForm.isFormValid(reportForm);
    }

    this.setState({
      reportForm: isReportType ? this.defineReportForm(reportType) : reportForm
    }, () => {
      if (isReportType) {
        this.preselectReportFields(reportType);
      }
    });

    if (fieldName === "sortByDate") {
      this.setState({
        isSortSettingsOpen: false
      });
    }
  };

  getProviders = () => {
    const { companiesPsps, selectedCompany } = this.state;
    return selectedCompany ? companiesPsps[selectedCompany] : [];
  };

  getAccounts = () => {
    const { companiesAccounts, reportForm, selectedCompany } = this.state;
    if (selectedCompany && reportForm.pspIds && reportForm.currency) {
      const filteredAccounts = companiesAccounts[selectedCompany].filter(acc => reportForm.pspIds.includes(String(acc.pspId)) ).filter(acc => reportForm.currency.includes(acc.currency));
      reportForm.accountIds = filteredAccounts.map(acc => acc.value);
      const result =  filteredAccounts.map(item => ({
        value: String(item.id),
        label: item.label
      }));
      reportForm.accountIds = result.map(acc => acc.value);
      return result;
    }
    
    return [];
  };

  getMidsByProvidersAndCurrencies = (isPayout, filterByCurrency, filterByAccount, filterByTrafficType) => {
    const {
      companiesClientsMids,
      companiesClientsPayoutMids,
      reportForm,
      selectedCompany
    } = this.state;

    let mids = companiesClientsMids[selectedCompany];
    if (isPayout) {
      mids = companiesClientsPayoutMids[selectedCompany];
    }
    const filteredMids = [];
    if (mids && reportForm.pspIds) {
      for (const [key, value] of Object.entries(mids)) {
        if (reportForm.clientIds && reportForm.clientIds.includes(key)) {
          if (value.length) {
            value.forEach(item => {
              let condition = reportForm.pspIds.includes(item.pspId.toString());
              if (filterByCurrency) {
                condition = condition && reportForm.currency.includes(item.currency);
              }
              if (filterByAccount && reportForm.accountIds) {
                condition = condition && reportForm.accountIds.includes(item.accountId.toString());
              }
              if (filterByTrafficType && reportForm.trafficTypes) {
                condition = condition && reportForm.trafficTypes.includes(item.trafficType);
              }
              if (condition) {
                if (!filteredMids.some(e => e.value === item.value)) {
                  filteredMids.push(item);
                }
              }
            });
          }
        }
      }
      return filteredMids;
    }
    return [];
  };

  getRunningBalanceCurrencies = () => {
    const { reportForm, companiesClientsMids, selectedCompany } = this.state;
    const clientsMids = companiesClientsMids[selectedCompany];
    if (!clientsMids || !reportForm.clientIds) {
      return [];
    }

    let mids = clientsMids[reportForm.clientIds];
    if (reportForm.clientIds.length > 1) {
      mids = [];
      reportForm.clientIds.forEach(clientId => {
        if (clientsMids[clientId]) {
          mids.push(...clientsMids[clientId]);
        }
      });
    }
    if (!mids) {
      return [];
    }
    const findCurrency = mids.map(mid => mid.currency);

    const setData = new Set(findCurrency);
    const uniqueCurrencyArray = Array.from(setData);
    uniqueCurrencyArray.sort((elemA, elemB) => elemA.localeCompare(elemB));
    const uniqueCurrencyObj = uniqueCurrencyArray.map(currency => {
      const obj = {};
      obj.value = currency;
      obj.label = currency;
      return obj;
    });

    return uniqueCurrencyObj;
  };

  mapSelectedItems = (type, lookupsName, useReportType) => {
    const { reportForm, companiesClients, selectedCompany } = this.state;
    const field = reportForm[type];
    const { reportType } = reportForm;
    let lookup = null;
    if (lookupsName === "clientIds") {
      lookup = selectedCompany ? companiesClients[selectedCompany] : [];
    } else if (lookupsName === "pspIds") {
      lookup = this.getProviders();
    } else if (lookupsName === "midIds") {
      const shouldFilterByCurrency = reportType === "TRANSACTIONS" || reportType === "RUNNING_BALANCE" || reportType === "CONVERSION_RATE" ||
        reportType === "SETTLEMENTS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "FRAUD_CHECK";
      lookup = this.getMidsByProvidersAndCurrencies(
        reportType === "PAYOUT",
        shouldFilterByCurrency,
        reportType === "RUNNING_BALANCE",
        reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"
      );
    } else if (lookupsName === "accountIds") {
      lookup = this.getAccounts();
    } else if (useReportType) {
      lookup = this.state[lookupsName][reportType];
    } else {
      if (type === "depositStatus" && reportType === "DEPOSITS_PAYOUTS") {
        lookup = this.state.depositPayoutStatus;
      } else {
        lookup = this.state[lookupsName];
      }
    }
    if (!field || !lookup || !Array.isArray(field) || field.some(elem => !lookup.find(lookupElem => elem === lookupElem.value))) {
      return [];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  filterDisplayMode = () => {
    const { displayMode, reportForm } = this.state;

    if (reportForm.reportType !== "GROSS_PROFIT") {
      return displayMode.filter(elem => elem.value === "LIST");
    }

    return displayMode;
  };

  onSortByOpen = () => {
    this.setState({
      isSortSettingsOpen: !this.state.isSortSettingsOpen
    });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  isRightAligned = (index) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers[index].headerType === "NUMBER";
  };

  filterHeaders = (headers) => {
    const { access } = this.state;
    let filterHeader = headers.filter(elem => {
      if (!elem.permission) {
        return true;
      }

      const foundPermission = access.find(accessElem => accessElem.permission === elem.permission);
      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });

    const findButtonAccess = access.find(accessElem => accessElem.permission);
    
    const findButton = headers.find(elem => elem.permission === "CHECK_STATUS");

    if (findButtonAccess.permission === "CHECK_STATUS" && findButtonAccess.state === false) {
      filterHeader = filterHeader.concat(findButton);
    }

    return filterHeader;

  };

  filterData = (data) => {
    const { reportsGenerated, access } = this.state;
    const filteredData = data.filter((elem, index) => {
      const header = reportsGenerated.headers[index];

      const foundPermission = access.find(accessElem => accessElem.permission === header.permission);

      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });

    return filteredData;

  };

  checkAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }
    return foundPermission.state;
  };

  onOpenModalClick = (data, type) => {
    const { reportForm } = this.state;
    if (type === "json" && (data === "{}" || data === "[]" || !data)) {
      return;
    }

    try {
      const modalData = type === "json" && !Array.isArray(data) ? JSON.parse(data) : data;

      this.setState({
        showModal: true,
        modalType: type,
        modalTitle: reportForm.reportType === "SETTLEMENTS" ? "Settlement Notes" : "Notes",
        modalData
      });
    } catch {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: type === "json" ? "Parsing logs failed." : "Getting notes failed.",
      });
    }
  };

  findMerchantIdIndex = (headers) => {
    return headers.filter(elem => "merchant_id" === elem.headerId).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );
  };

  triggerButtonEvent = (headers, headerIndex, row, tableData, tableDataType) => {
    const { reportsGenerated, savedSelectedCompany, savedGeneratedReport } = this.state;
    const filterHeders = this.filterHeaders(headers);

    const buttonSettings = filterHeders.at(headerIndex);

    const headersIndexes = headers.filter(elem => buttonSettings.fields.includes(elem.headerId)).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );
    const mercantIdIndex = this.findMerchantIdIndex(headers);

    const data = {};

    headersIndexes.forEach(
      elem => {
        const foundHeader = headers[elem];
        data[foundHeader.headerId] = row[elem];
      }
    );
    const merchantId = row[mercantIdIndex[0]];

    if (buttonSettings.endpoint.includes("/v1/report-buttons/performance/")) {
      const { blockLoader } = this.state;
      const type = buttonSettings.endpoint === "/v1/report-buttons/performance/email/block" ? "Email" : "IP Address";
      const valueIndex = headers.findIndex(header => header.headerName === type);
      const buttonIndex = headers.findIndex(header => header.headerType === "BUTTON");
      
      if (row[valueIndex]) {
        blockLoader.push(row[valueIndex]);
        this.setState({
          blockLoader
        });

        const blockData = {
          whiteLabelId: savedSelectedCompany,
          block: !row[buttonIndex],
          value: row[valueIndex]
        };

        crudActions.post(buttonSettings.endpoint, blockData).then(
          (response) => {
            const rowIndex = reportsGenerated.data.findIndex(item => item[valueIndex] === row[valueIndex]);
            row[buttonIndex] = !row[buttonIndex];
            reportsGenerated.data[rowIndex] = row;

            this.setState({
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successful.",
              blockLoader: this.state.blockLoader.filter(i => i !== row[valueIndex]),
              reportsGenerated
            });
          }
        ).catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                blockLoader: this.state.blockLoader.filter(i => i !== row[valueIndex])
              });
            }
          }
        );
      } 
    } else if (buttonSettings.endpoint === "/v1/report-buttons/deposits/check-status" || (savedGeneratedReport.reportType === "DEPOSITS_PAYOUTS" && !buttonSettings.endpoint.includes("v1/report-buttons/transactions"))) {
      this.setState({
        isButtonLoading: row[this.getReportColumnIndex('id')],
        loadingButtonType: buttonSettings.headerId
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Company: savedSelectedCompany,
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isButtonLoading: "",
            loadingButtonType: ""
          });
          const status = response.messageType;
          if (status === "SUCCESS") {
            this.setState({
              showAlert: true,
              alertType: "success",
              alertMessage: response.message
            });
          } else if (status === "ERROR") {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: response.message,
            });
          } else if (status === "NEUTRAL" || !status) {
            this.setState({
              showAlert: true,
              alertType: "info",
              alertMessage: response.message
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isButtonLoading: "",
              loadingButtonType: ""
            });
          }
        }
      );
    } else if (buttonSettings.endpoint.includes("v1/report-buttons/transactions")) {
      this.setState({
        isModalLoading: true,
        showModal: true,
        modalType: tableDataType,
        modalTitle: "Logs",
        modalData: ""
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Company: savedSelectedCompany,
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isModalLoading: false,
            modalData: JSON.parse(JSON.stringify(response))
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              showModal: false,
              modalTitle: "",
            });
          }
        }
      );
    } else {
      this.setState({
        isButtonLoading: row[this.getReportColumnIndex('id')],
        loadingButtonType: buttonSettings.headerId
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Company: savedSelectedCompany,
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isButtonLoading: "",
            loadingButtonType: "",
            showAlert: true,
            alertType: "success",
            alertMessage: response && response.message ? response.message : "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isButtonLoading: "",
              loadingButtonType: ""
            });
          }
        }
      );
    }
  };

  sendBulkPostback = () => {
    const { reportsGenerated, reportForm, savedSelectedCompany, savedGeneratedReport} = this.state;
    const headers = reportsGenerated.headers;
    const data = reportsGenerated.data;
    const transactionIds = [];
    let index = null;
    if (data && headers) {
      if (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "RISK_AND_SECURITY" || reportForm.reportType  === "FRAUD_CHECK") {
        index = headers.map(e => e.headerName).indexOf("ID");
      }
      if (reportForm.reportType === "PAYOUT") {
        index = headers.map(e => e.headerName).indexOf("Payout ID");
      }
      data.forEach(d => {
        transactionIds.push(d[index]);
      });
    }
    const objectTransactionsIds = {};
    objectTransactionsIds.ids = transactionIds;
    if (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "RISK_AND_SECURITY") {
      (crudActions.post('v1/report-buttons/deposit/bulk', objectTransactionsIds,{
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      }
      )).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }
        }
      );

    }
    if (reportForm.reportType === "PAYOUT") {
      (crudActions.post('/v1/report-buttons/payout/bulk', objectTransactionsIds, {
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      })).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }
        }
      );
    }
  };

  onGenerateReport = () => {
    const { reportForm, timeStamp, selectedCompany, endpointGenerateMap, currentPage, rowsAmount, periodId, loadingRBReportId, rbIntervalID, controls, timeZones, companiesTimezoneTypes, periods } = this.state;
    const isReportFormValid = reportForm.isFormValid(reportForm);
    this.setState({
      reportForm: reportForm,
      isReportErrorFields: !isReportFormValid,
      clickButton: true
    });
    if (reportForm.transactionId) {
      this.setState({
        enableButton: true
      });
    }

    if (isReportFormValid) {
      this.setState({
        isLoading: true
      });

      const reportFormData = reportForm.data();

      const reportType = reportFormData.reportType;
      const displayMode = reportFormData.displayMode;

      if (reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" || reportType === "PAYOUT" ||
        reportType === "RISK_AND_SECURITY" || reportType === "FRAUD_CHECK" || reportType === "DEPOSITS_PAYOUTS") {
        reportFormData.transactionId = reportFormData.transactionId.trim();
        reportFormData.timeZoneType = companiesTimezoneTypes && companiesTimezoneTypes[selectedCompany];
        reportFormData.timePeriod = periods.find(period => period.id === periodId).value;
      }
      if (reportType === "RUNNING_BALANCE") {
        delete reportFormData.currency;
      }
      let dateFormat = "DD.MM.YYYY HH:mm:ss";
      if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
        dateFormat = "DD.MM.YYYY";
      }
      if (reportType !== "SERVER_LOGS") {
        delete reportFormData.currency;
      }
      if (reportType === "CONVERSION_RATE") {
        delete reportFormData.depositMethod;
        delete reportFormData.depositStatus;
      }
      delete reportFormData.pspIds;

      const period = this.getPeriod(periodId);

      if (periodId !== 8) {
        //we do not override dates for custom date picker
        timeStamp.fromDateTimeStamp = period.from;
        timeStamp.toDateTimeStamp = period.to;
      }

      let timeZoneOffset = "00:00";
      const selectedCompanyTimezoneType = companiesTimezoneTypes[selectedCompany];
      if (reportForm.timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
        timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
      }

      let fromDate = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
      let toDate = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
        
      if (periodId === 8) {
        fromDate = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
        toDate = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
      }

      let endpoint = endpointGenerateMap[reportType][displayMode];
      if (loadingRBReportId) {
        endpoint += `/${loadingRBReportId}`;
      }

      (loadingRBReportId ? crudActions.get : crudActions.post)(
        endpoint,
        Object.assign(reportFormData, {
          fromDate,
          toDate,
          companyId: selectedCompany,
          pageNumber: currentPage,
          limit: rowsAmount,
          ...(reportType === "RUNNING_BALANCE" && { timePeriod: controls.timePeriod.value })
        })
      ).then(
        (reportsGenerated) => {
          if (reportsGenerated) {
            if (reportsGenerated.status === "PENDING" && reportsGenerated.id) {
              this.setState({
                loadingRBReportId: reportsGenerated.id,
                savedGeneratedReport: reportFormData,
                savedSelectedCompany: selectedCompany
              }, () => {
                if (!rbIntervalID) {
                  const interval = setInterval(() => {
                    this.onGenerateReport();
                  }, this.getTimeout(reportFormData.clientIds.length));
                  this.setState({
                    rbIntervalID: interval
                  });
                }
              });
            } else if (reportsGenerated.status && reportsGenerated.id) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: "Oops, something went wrong. Please, try again or change filter parameters.",
                rbIntervalID: null,
                loadingRBReportId: "",
                isLoading: false,
                isReportErrorFields: false,
                editableTransactionId: "",
                editableTransactionField: ""
              });
              clearInterval(rbIntervalID);
            } else {
              if (displayMode === "LIST_DETAILED") {
                reportsGenerated.isListDetailed = true;
                reportsGenerated.data = reportsGenerated.data.reduce(
                  (finalArray, currentElem) => {
                    finalArray.push({
                      mainRow: true,
                      content: currentElem.mainRow.concat(Array(reportsGenerated.headers.length - currentElem.mainRow.length).fill(""))
                    });
                    finalArray = finalArray.concat(currentElem.subRows.map(elem => {
                      return {
                        mainRow: false,
                        content: Array(reportsGenerated.headers.length - elem.length).fill("").concat(elem)
                      };
                    }));

                    return finalArray;
                  }, []
                );
              } else if (
                reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" ||
                reportType === "PAYOUT" || reportType === "RISK_AND_SECURITY" ||
                reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
                reportType === "DEPOSITS_PAYOUTS" || reportType === "PAY_COMMISSIONS"
              ) {
                reportsGenerated.isHistory = true;
              } else if (displayMode === "LIST" || displayMode === "LIST_MULTIPLE") {
                reportsGenerated.isList = true;
              }
              if (reportsGenerated.warningMessage) {
                this.setState({
                  showAlert: true,
                  alertType: "info",
                  alertMessage: reportsGenerated.warningMessage,
                });
              }
              this.setState({
                rbIntervalID: null,
                loadingRBReportId: "",
                reportsGenerated: reportsGenerated,
                savedGeneratedReport: reportFormData,
                savedSelectedCompany: selectedCompany,
                isLoading: false,
                isReportErrorFields: false,
                cnt: reportsGenerated.cnt,
                lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
                editableTransactionId: "",
                editableTransactionField: ""
              });
              clearInterval(rbIntervalID);
            }
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false,
              rbIntervalID: null,
              loadingRBReportId: ""
            });
            clearInterval(rbIntervalID);
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them in order to continue.",
      });
    }
  };

  onExportClick = () => {
    const { reportForm, timeStamp, selectedCompany, endpointXlsxMap, periodId, loadingRBReportId, rbIntervalID, controls, timeZones, companiesTimezoneTypes, periods } = this.state;

    const reportFormData = reportForm.data();
    const reportType = reportFormData.reportType;
    const displayMode = reportFormData.displayMode;

    const isReportFormValid = reportForm.isFormValid(reportForm);
    this.setState({
      reportForm: reportForm,
      isExportSettingsOpen: false
    });

    let dateFormat = "DD.MM.YYYY HH:mm:ss";
    if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
      dateFormat = "DD.MM.YYYY";
    }
    if (reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" || reportType === "PAYOUT" ||
      reportType === "RISK_AND_SECURITY" || reportType === "FRAUD_CHECK" || reportType === "DEPOSITS_PAYOUTS") {
      reportFormData.timeZoneType = companiesTimezoneTypes && companiesTimezoneTypes[selectedCompany];
      reportFormData.timePeriod = periods.find(period => period.id === periodId).value;
    }

    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    let timeZoneOffset = "00:00";
    const selectedCompanyTimezoneType = companiesTimezoneTypes[selectedCompany];
    if (reportForm.timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }

    let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
    let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
        
    if (periodId === 8) {
      fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
      toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
    }

    let endpoint = endpointXlsxMap[reportType][displayMode];
    if (loadingRBReportId) {
      endpoint += `/${loadingRBReportId}`;
    }

    if (isReportFormValid) {
      if (!loadingRBReportId) {
        this.setState({
          showAlert: true,
          alertType: "info",
          alertMessage: "Export in progress. It might take some time, depends on data volume."
        });
      }
      (loadingRBReportId ? crudActions.get : crudActions.post)(
        endpoint,
        Object.assign(reportFormData, {
          fromDate: fromDateTimeStamp,
          toDate: toDateTimeStamp,
          companyId: selectedCompany,
          pageNumber: 1,
          limit: INTEGER_MAX,
          currency: reportFormData.currency,
          ...(reportType === "RUNNING_BALANCE" && { timePeriod: controls.timePeriod.value })
        })
      ).then(
        response => {
          if (response.status === "PENDING" && response.id) {
            this.setState({
              loadingRBReportId: response.id
            }, () => {
              if (!rbIntervalID) {
                const interval = setInterval(() => {
                  this.onExportClick();
                }, this.getTimeout(reportFormData.clientIds.length));
                this.setState({
                  rbIntervalID: interval
                });
              }
            });
          } else if (response && response.ok) {
            response.blob().then(
              result => {
                saveAs(result, `${reportType}.xlsx`);
              }
            );
            this.setState({
              isLoading: false,
              rbIntervalID: null,
              loadingRBReportId: ""
            });
            clearInterval(rbIntervalID);
          } else {
            return Promise.reject({
              message: "Request can not be processed."
            });
          }
        }
      ).catch(
        err => {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            rbIntervalID: null,
            loadingRBReportId: ""
          });
          clearInterval(rbIntervalID);
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them in order to continue.",
      });
    }
  };

  getTimeout = (clients) => {
    if (clients <= 5) {
      return 5000;
    } else if (clients > 5 && clients <= 15) {
      return 10000;
    } else if (clients > 15) {
      return 15000;
    }
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon"
      || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
      || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
      if(event.target.textContent){
        pageNum = event.target.textContent;
      }
   
    this.setState({ currentPage: pageNum }, () => {
      this.onGenerateReport();
    }); 
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalData: "{}",
      modalType: "json",
      modalTitle: ""
    });
  };

  getReportColumnIndex = (headerId) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers.map(e => e.headerId).indexOf(headerId);
  };

  getStatusSelectOptions = (currStatus) => {
    if (currStatus === "PENDING") {
      return [{
        label: "PENDING",
        value:"PENDING"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "FAIL",
        value:"FAIL"
      }];
    } else if (currStatus === "FAIL") {
      return [{
        label: "FAIL",
        value:"FAIL"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "REJECTED") {
      return [{
        label: "REJECTED",
        value:"REJECTED"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "CANCELLED") {
      return [{
        label: "CANCELLED",
        value:"CANCELLED"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "FRAUD_CHECK") {
      return [{
        label: "FRAUD_CHECK",
        value:"FRAUD_CHECK"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "FAIL",
        value:"FAIL"
      }, {
        label: "REVERSED",
        value:"REVERSED"
      }];
    } else {
      return [{
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "REVERSED",
        value:"REVERSED"
      }];
    }
  };

  onDoubleClick = (fieldName, currValue, transId, transactionStatus, transactionType) => {
    const { reportForm } = this.state;
    if (!transactionType) {
      transactionType = "DEPOSIT";
      if ((reportForm.reportType === "DEPOSITS_PAYOUTS" && transactionStatus === "PAYOUT") || reportForm.reportType === "PAYOUT") {
        transactionType = "PAYOUT";
      }
    }
    if (fieldName === "status" && currValue !== "PENDING" && currValue !== "SUCCESS" && currValue !== "FAIL" && currValue !== "CANCELLED" && currValue !== "REJECTED" && currValue !== "FRAUD_CHECK" ) return;
    if (fieldName === "amount" && transactionStatus !== "PENDING" && transactionStatus !== "FAIL" && transactionStatus !== "REJECTED" && transactionStatus !== "CANCELLED" && transactionStatus !== "FRAUD_CHECK" && transactionStatus !== "SUCCESS") return;
    if (transactionType === "PAYOUT" && fieldName === "amount") return;
    if (transactionType === "PAYOUT" && currValue === "REJECTED") return;
    this.setState({
      editableTransactionId: transId,
      editableTransactionField: fieldName,
      editableTransactionValue: currValue
    });
  };

  handleEnterPress = (event, trxStatus) => {
    const { editableTransactionField } = this.state;
    if (event.key === "Enter" && parseFloat(this.state.editableTransactionValue) > 0) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to change the transaction ${editableTransactionField}? ${trxStatus === "SUCCESS" ? "There is no guarantee that the merchant has consumed this callback automatically." : ""}`
      });
    }
  };

  onChangeTxValue = (fieldName, transId, prevValue, newValue) => {
    const { editableTransactionField } = this.state;
    if (fieldName === "status") {
      if (newValue !== prevValue) {
        this.setState({
          showPromptModal: true,
          changeValueMessage: `Do you want to change the ${editableTransactionField}?`
        });
      }
    }
    this.setState({
      editableTransactionValue: newValue,
      editableTransactionField: fieldName
    });
  };

  handleConfirmPromptModal = () => {
    const {
      companiesTimezoneTypes,
      currentPage,
      periodId,
      periods,
      reportForm,
      reportsGenerated,
      rowsAmount,
      selectedCompany,
      editableTransactionValue,
      editableTransactionId,
      editableTransactionField,
      timeStamp
    } = this.state;

    const transIdIndex = reportsGenerated.headers.findIndex(header => header.headerName === "ID");
    const transTypeIndex = reportsGenerated.headers.findIndex(header => header.headerName === "Type");
    const amountIndex = reportsGenerated.headers.findIndex(header => header.headerName === "Amount");
    const statusIndex = reportsGenerated.headers.findIndex(header => header.headerName === "Status");

    const row = reportsGenerated.data.find(item => item[transIdIndex] === editableTransactionId);
    const reportFormData = reportForm.data();

    if (row) {
      this.setState({
        changeTXPropertyLoading: true
      });

      let transactionType = "deposits";
      if ((reportFormData.reportType === "DEPOSITS_PAYOUTS" && row[transTypeIndex] === "PAYOUT") || reportFormData.reportType === "PAYOUT") {
        transactionType = "payouts";
      }

      const changes = addLogItem(
        [],
        "REPORT_UPDATE_" + transactionType.toUpperCase() + "_" + editableTransactionField.toUpperCase(),
        editableTransactionField === "status" ? row[statusIndex] : row[amountIndex],
        editableTransactionValue,
        editableTransactionField,
        row[transIdIndex]
      );

      const data = {
        "id": editableTransactionId,
        ...(editableTransactionField === "status" && { "status": editableTransactionValue }),
        ...(editableTransactionField === "amount" && { "amount": editableTransactionValue }),
        companyId: selectedCompany,
        changes,
        "reportFilter": Object.assign(reportFormData, {
          fromDate: getFormattedDate(timeStamp.fromDateTimeStamp._d ? timeStamp.fromDateTimeStamp._d : timeStamp.fromDateTimeStamp),
          toDate: getFormattedDate(timeStamp.toDateTimeStamp._d ? timeStamp.toDateTimeStamp._d : timeStamp.toDateTimeStamp),
          timeZoneType: companiesTimezoneTypes && companiesTimezoneTypes[selectedCompany],
          timePeriod: periods.find(period => period.id === periodId).value,
          companyId: selectedCompany,
          pageNumber: currentPage,
          limit: rowsAmount
        })
      };

      crudActions.put(
        `v1/report-buttons/${transactionType}/update`,
        data
      ).then( (response) => {
        if (reportForm.reportType  === "FRAUD_CHECK") {
          //for Fraud report we just remove transaction from report because it will not be there anymore
          reportsGenerated.data.splice(transIdIndex);
          this.setState({
            showPromptModal: false,
            changeValueMessage: "",
            editableTransactionId: "",
            editableTransactionField: "",
            changeTXPropertyLoading: false,
            showAlert: true,
            alertType: "success",
            alertMessage: "Transaction status has been updated!",
            reportsGenerated
          });
        } else {
          if (response.data && response.data.length) {
            const transaction = response.data[0];
            const transId = transaction[transIdIndex];
            const isExistingTransaction = reportsGenerated.data.find(item => item[transIdIndex] === transId);

            if (isExistingTransaction) {
              const rowIndex = reportsGenerated.data.findIndex(item => item[transIdIndex] === transId);
              reportsGenerated.data[rowIndex] = transaction;
            } else {
              reportsGenerated.data.unshift(transaction);
            }

            this.setState({
              showPromptModal: false,
              changeValueMessage: "",
              editableTransactionId: "",
              editableTransactionField: "",
              changeTXPropertyLoading: false,
              showAlert: true,
              alertType: "success",
              alertMessage: `Transaction ${editableTransactionField} has been updated!`,
              reportsGenerated
            });
          }
        }
      }).catch(
        err => {
          if (err && err.message && typeof err.message === "string") {
            const condition = err.message.includes("Postback was not consumed by the merchant");
            if (condition) {
              const rowIndex = reportsGenerated.data.findIndex(item => item[transIdIndex] === editableTransactionId);
              reportsGenerated.data[rowIndex][this.getReportColumnIndex(editableTransactionField)] = editableTransactionValue;
              this.setState({
                editableTransactionId: "",
                editableTransactionField: "",
                reportsGenerated
              });
            }
            this.setState({
              showAlert: true,
              alertType: condition ? "warning" : "error",
              alertMessage: err.message,
              showPromptModal: false,
              changeTXPropertyLoading: false,
              changeValueMessage: "",
            });
          } else {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: `Couldn't update transaction ${editableTransactionField}.`,
              showPromptModal: false,
              changeTXPropertyLoading: false,
              changeValueMessage: "",
              editableTransactionId: "",
              editableTransactionField: "",
            });
          }
        }
      );
    }
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  getAlertButtonColor = () => {
    switch (this.state.alertType) {
    case "error":
      return "#DD6B55";
    case "warning":
      return "#F8BB86";
    default:
      return "#187EED";
    }
  };

  onAlertConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "error",
      alertMessage: ""
    });
  };

  getIncomeFundsColumns = () => {
    return [{
      value: "date",
      label: "Date",
      leftAligned: true,
      isNumber: false
    }, {
      value: "provider",
      label: "Provider",
      leftAligned: true,
      isNumber: false
    }, {
      value: "account",
      label: "Account",
      leftAligned: true,
      isNumber: true
    }, {
      value: "fxPair",
      label: "FX Pair",
      leftAligned: true,
      isNumber: false
    }, {
      value: "currency",
      label: "Currency",
      leftAligned: true,
      isNumber: false
    }, {
      value: "requested",
      label: "Requested",
      rightAligned: true,
      isNumber: true
    }, {
      value: "fxRate",
      label: "FX Rate",
      rightAligned: true,
      isNumber: true
    }, {
      value: "status",
      label: "Status",
      rightAligned: true,
      isNumber: true
    }, {
      value: "invoice",
      label: "Invoice #",
      rightAligned: true,
      isNumber: true
    }, {
      value: "hash",
      label: "Hash",
      rightAligned: true,
      isNumber: true
    }, {
      value: "notes",
      label: "Notes",
      rightAligned: true,
      isNumber: true
    },{
      value: "incomingFunds",
      label: "Incoming funds",
      rightAligned: true,
      isNumber: true
    }];
  };

  tableIncomingFundsData = () => {
    return [{ date: "11.07.2000", provider: "365PSP", account: 10, fxPair: "SGD/USDT", currency: "SGD",
      requested: "100.000", fxRate: 1.50, status: "Paid",  invoice: "123", hash: "tron.com", notes: "OP", incomingFunds: 200000,
      childNodes: [{ date: "", provider: "", account: 11, fxPair: "", currency: "",
        requested: "10.000", fxRate: 1.50, status: "",  invoice: "", hash: "", notes: "", incomingFunds: 2000}, { date: "", provider: "", account: 12, fxPair: "", currency: "",
        requested: "10.000", fxRate: 1.50, status: "",  invoice: "", hash: "", notes: "", incomingFunds: 3000}]
    },{ date: "11.07.2000", provider: "365PSP", account: 10, fxPair: "SGD/USDT", currency: "SGD",
      requested: "100.000", fxRate: 1.50, status: "Paid",  invoice: "123", hash: "tron.com", notes: "OP", incomingFunds: 200000,
      childNodes: [{ date: "", provider: "", account: 11, fxPair: "", currency: "",
        requested: "10.000", fxRate: 1.50, status: "",  invoice: "", hash: "", notes: "", incomingFunds: 2000}, { date: "", provider: "", account: 12, fxPair: "", currency: "",
        requested: "10.000", fxRate: 1.50, status: "",  invoice: "", hash: "", notes: "", incomingFunds: 3000}]
    }];
  };

  mapHeadersToArray = (headers) => {
    return headers.map(header => {
      return {
        value: header.headerId,
        label: header.headerName,
        leftAligned: header.headerType === "STRING" || header.headerType === "DATE",
        rightAligned: header.headerType === "NUMBER",
        isNumber: header.headerType === "NUMBER"
      };
    });
  };

  transformData = (data, headers) => {
    return data.map(item => {
      const transformedItem = {};

      headers.forEach((header, index) => {
        transformedItem[header.headerId] = item[index];
      });

      transformedItem.childNodes = [];

      item.slice(headers.length).forEach(childItem => {
        if (Array.isArray(childItem) && childItem.length > 0) {
          childItem.forEach(elem => {
            const childNode = {};
            headers.forEach((header, index) => {
              if (index < elem.length) {
                childNode[header.headerId] = elem[index];
              }
            });
            transformedItem.childNodes.push(childNode);
          });
        }
      });

      return transformedItem;
    });
  };

  onExpandCollapseClick = () => {
    this.setState({
      expandAll: !this.state.expandAll
    });
  };

  render() {
    const controls = { ...this.state.controls };

    const {
      agents,
      alertMessage,
      alertType,
      baseCurrencies,
      blockLoader,
      companies,
      companiesClients,
      clickButton,
      cnt,
      currentPage,
      dateFieldDisabled,
      editableTransactionId,
      editableTransactionField,
      editableTransactionValue,
      enableButton,
      expandAll,
      isButtonLoading,
      isExportSettingsOpen,
      isLoading,
      isLoadingLookups,
      isModalLoading,
      isSortSettingsOpen,
      loadingButtonType,
      modalData,
      modalTitle,
      modalType,
      pageType,
      reportForm,
      reportType,
      reportsGenerated,
      roleId,
      rowsAmount,
      savedGeneratedReport,
      selectedCompany,
      showAlert,
      showModal,
      showPromptModal,
      sortByDate,
      timeStamp,
      timeStampList,
      timeZones
    } = this.state;

    if (!isSortSettingsOpen) {
      this.sortNode = null;
    }

    const totalPageCount = Math.ceil(cnt / rowsAmount);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    const rows = this.getFields();

    const isMultiMerchant = reportForm.reportType !== "RISK_AND_SECURITY" && reportForm.reportType !== "SERVER_LOGS";

    const isDateTime = reportForm.reportType !== "SETTLEMENTS" && reportForm.reportType !== "GROSS_PROFIT";

    const isTimeVisible = reportForm.reportType !== "RUNNING_BALANCE" || (reportForm.reportType === "RUNNING_BALANCE" && controls.timePeriod.value === "custom");

    const isChangeStatusPossible = reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "FRAUD_CHECK" || reportForm.reportType === "DEPOSITS_PAYOUTS" || reportForm.reportType === "PAYOUT";

    if (!reportForm.originalData) {
      return <Row flexGrow={ 1 } className='module generateReports' vertical='start'>
        <span />
      </Row>;
    }
    return (
      <Row flexGrow={ 1 } className="module generateReports" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ pageType === 'transactions' ? "Transactions" : "Generate Report" }
            lastUpdate={ this.state.lastUpdate }
            img={ ReportIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    GENERATE REPORT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>
                    {isLoadingLookups ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> White Label </label>
                              <Select
                                id="company"
                                name="company"
                                value={ selectedCompany || '' }
                                required={ true }
                                clearable={ false }
                                disabled={ isLoading }
                                onChange={ (value) => this.onSelectCompanyChange(value) }
                                options={ companies }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Report Type </label>
                              <Select
                                id="reportType"
                                name="reportType"
                                value={ reportForm.reportType || '' }
                                required={ true }
                                clearable={ false }
                                enableSort={ true }
                                disabled={ !selectedCompany || isLoading }
                                onChange={ (e) => this.onValueChange(e, 'reportType') }
                                options={ reportType }
                              />
                            </Column>
                            { (reportForm.reportType !== "AGENTS_COMMISSIONS" && reportForm.reportType !== "INCOMING_FUNDS" && reportForm.reportType !== "PAY_COMMISSIONS") ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Merchants </label>
                                {isMultiMerchant ? (
                                  <Multiselect
                                    id="clientIds"
                                    name="clientIds"
                                    isError={ reportForm.errors.has('clientIds') }
                                    selectedItems={ this.mapSelectedItems("clientIds", "clientIds") }
                                    disabled={ !selectedCompany || isLoading }
                                    items={ selectedCompany ? companiesClients[selectedCompany] : [] }
                                    type="clientIds"
                                    onChange={ this.onValueChange }
                                  />
                                ) : (
                                  <Select
                                    id="clientIds"
                                    className={ reportForm.errors.has('clientIds') ? 'error-field' : "" }
                                    value={ reportForm.clientIds.toString() }
                                    disabled={ !selectedCompany || isLoading }
                                    required={ true }
                                    clearable={ false }
                                    options={ selectedCompany ? companiesClients[selectedCompany] : [] }
                                    onChange={ (e) => this.onValueChange(e, 'clientIds') }
                                  />
                                )}
                              </Column>
                            ) : reportForm.reportType === "PAY_COMMISSIONS" ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                <label> Agents </label>
                                <Multiselect
                                  isError={ reportForm.errors.has("agentIds") }
                                  selectedItems={ this.mapSelectedItems("agentIds", "agents") }
                                  disabled={ !selectedCompany || isLoading }
                                  items={ agents.filter(a => a.companyId.toString() === selectedCompany) }
                                  type="agentIds"
                                  withId={ false }
                                  onChange={ this.onValueChange }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                <span/>
                              </Column>
                            )}
                            {(reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" ||
                              reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "DEPOSITS_PAYOUTS") ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Stamp </label>
                                  <Select
                                    id="timeStampList"
                                    name="timeStampList"
                                    className={ reportForm.errors.has('timeStampList') ? 'error-field' : "" }
                                    value={ reportForm.timeStampList }
                                    disabled={ !selectedCompany || isLoading }
                                    required={ true }
                                    clearable={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeStampList') }
                                    options={ timeStampList }
                                  />
                                </Column>
                              ) : (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                  <span />
                                </Column>
                              )}
                          </Row>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Time Period </label>
                              <Select
                                id="timePeriod"
                                name="timePeriod"
                                value={ controls.timePeriod.value || '' }
                                disabled={ !selectedCompany || isLoading }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                                options={ this.getFilteredPeriods(reportForm.reportType) }
                              />
                            </Column>
                            {isTimeVisible ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label>From Date</label>
                                <img src={ Calendar } className="calendar-svg" alt="" />
                                <DatePicker
                                  selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                                  className="form-control"
                                  timeInputLabel="Time:"
                                  dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                  timeFormat="HH:mm"
                                  showTimeSelect={ isDateTime }
                                  timeIntervals={ 15 }
                                  filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                                  disabled={ dateFieldDisabled || !selectedCompany || isLoading }
                                  onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <span/>
                              </Column>
                            )}
                              
                          </Row>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            {isTimeVisible ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label>To Date</label>
                                <img src={ Calendar } className="calendar-svg" alt="" />
                                <DatePicker
                                  selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                                  className="form-control"
                                  timeInputLabel="Time:"
                                  dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                  timeFormat="HH:mm"
                                  showTimeSelect={ isDateTime }
                                  timeIntervals={ 15 }
                                  filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                                  disabled={ dateFieldDisabled || !selectedCompany || isLoading }
                                  onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <span/>
                              </Column>
                            )}
                            {reportForm.reportType === "GROSS_PROFIT" ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Currency </label>
                                <Select
                                  id="grossCurrency"
                                  name="grossCurrency"
                                  className={ reportForm.errors.has('grossCurrency') ? 'error-field' : "" }
                                  value={ reportForm.grossCurrency }
                                  disabled={ !selectedCompany || isLoading }
                                  required={ true }
                                  clearable={ false }
                                  onChange={ (value) => this.onValueChange(value, 'grossCurrency') }
                                  options={ baseCurrencies }
                                />
                              </Column>
                            ) : reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" || reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "DEPOSITS_PAYOUTS" || reportForm.reportType === "CONVERSION_RATE" ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Time Zone </label>
                                <Select
                                  id="timeZone"
                                  name="timeZone"
                                  className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                  value={ reportForm.timeZone }
                                  disabled={ !selectedCompany || isLoading }
                                  required={ true }
                                  clearable={ false }
                                  onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                  options={ timeZones }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                <span />
                              </Column>
                            )}
                          </Row>
                        </Row>
                        {rows.map((row, index) => {
                          return (
                            <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                              {[2, 4].map(count => {
                                return (
                                  <Row key={ index + " " + count } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                                    {row.slice(count - 2, count).map((field, fieldIndex) => {
                                      return field.id !== "empty" && (
                                        <Column
                                          key={ field.id + " " + index + " " + fieldIndex }
                                          flexGrow={ field.flexGrow !== undefined ? parseFloat(field.flexGrow) : 1 }
                                          vertical='end'
                                          alignSelf='start'
                                          className={ `input-column ${!field.type && "empty-column"}` }
                                        >
                                          {field.type !== "checkbox" && !field.notShowOnCondition && <label style={ { minHeight: '20px' } }> {field.label} </label>}

                                          {field.type === "select" && !field.notShowOnCondition ? (
                                            <Select
                                              className={ reportForm.errors.has(field.id) ? 'error-field' : "" }
                                              value={ field.id === "pspIds" || field.id === "currency" ? reportForm[field.id].toString() : reportForm[field.id] }
                                              disabled={ field.disabled || isLoading }
                                              required={ true }
                                              clearable={ false }
                                              options={ field.options }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                            />
                                          ) : field.type === "multiselect" && !field.notShowOnCondition ? (
                                            <Multiselect
                                              isError={ reportForm.errors.has(field.id) }
                                              selectedItems={ field.selectedItems }
                                              disabled={ field.disabled || isLoading }
                                              items={ field.items }
                                              type={ field.id }
                                              withId={ field.withId }
                                              onChange={ this.onValueChange }
                                            />
                                          ) : field.type === "input" && !field.notShowOnCondition ? (
                                            <input
                                              className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                              value={ reportForm[field.id] }
                                              disabled={ field.disabled || isLoading }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                            />
                                          ) : field.type === "textarea" && !field.notShowOnCondition ? (
                                            <TextareaAutosize
                                              cacheMeasurements
                                              value={ reportForm[field.id] }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                              className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                              disabled={ field.disabled || isLoading }
                                            />
                                          ) : field.type === "bulkButton" && enableButton === true ? (
                                            <CustomButton
                                              title="Send bulk postbacks"
                                              type="submit"
                                              disabled={ isLoading }
                                              style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                              onClick={ this.sendBulkPostback }
                                            />
                                          ) : field.type === "checkbox" && !field.notShowOnCondition ? (
                                            <Row className="styled-input--square" wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                              <div className="styled-input-single">
                                                <input
                                                  id={ field.id }
                                                  type="checkbox"
                                                  checked={ reportForm[field.id] }
                                                  disabled={ isLoading }
                                                  onChange={ () => this.onValueChange(reportForm[field.id], [field.id]) }
                                                />
                                                <label style={ { fontWeight: "normal" } } htmlFor={ field.id }> {field.label} </label>
                                              </div>
                                            </Row>
                                          ) : field.type === "button" && !field.notShowOnCondition ? (
                                            <CustomButton
                                              title="Generate"
                                              type="submit"
                                              disabled={ isLoading }
                                              style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                              onClick={ this.onGenerateReport }
                                            />
                                          ) : (
                                            <span />
                                          )}
                                        </Column>
                                      );
                                    })}
                                  </Row>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Panel.Body>
              </Panel>
              { !isLoading && reportsGenerated.headers && savedGeneratedReport.reportType === "INCOMING_FUNDS" &&  <Panel className="apidata" style={ { height: '100%' } }>
                <Panel.Heading>
                  <Panel.Title> REPORT GENERATED </Panel.Title>
                  <div className="settings-panel">
                    <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                      <svg className="setting-icon" viewBox="0 0 32 32">
                        <g>
                          <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                          <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                        </g>
                      </svg>
                      <span className="text"> Export </span>
                    </span>
                  </div>
                  {isSortSettingsOpen && <div ref={ node => this.sortNode = node } className="sort-settings">
                    {
                      sortByDate.map(elem => {
                        return <p key={ elem.value } className="sort-setting-item" onClick={ () => this.onValueChange(elem, 'sortByDate') }> {elem.label} </p>;
                      })
                    }
                  </div>
                  }
                  {isExportSettingsOpen && <div ref={ node => this.exportNode = node } className="export-settings">
                    <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                  </div>
                  }
                  <img
                    src={ expandAll ? CollapseAllIcon : ExpandAllIcon }
                    alt={ `${expandAll ? 'collapse' : 'expand'} all rows` }
                    className="expand-collapse-icon"
                    onClick={ () => this.onExpandCollapseClick() }
                  />
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content report-content table-content payout-requests accordion-table income-funds">
                    <Table
                      columns={ this.mapHeadersToArray(reportsGenerated.headers) }
                      headerColor="#223579"
                      data={ this.transformData(reportsGenerated.data, reportsGenerated.headers) }
                      isStriped={ true }
                      defaultSortBy="incomingFunds"
                      sortDirection="DESC"
                      expandAllRows={ expandAll }
                      useArrow={ true }
                    />
                  </div>
                </Panel.Body>
              </Panel>
              }
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> REPORT GENERATED </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : reportsGenerated.headers && savedGeneratedReport.reportType !== "INCOMING_FUNDS" ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      REPORT GENERATED
                    </Panel.Title>
                    <div className="settings-panel">
                      <span className="setting" onClick={ () => this.onSortByOpen() }>
                        <svg className="setting-icon" viewBox="0 0 1792 1792">
                          <path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z" />
                        </svg>
                        <span className="text"> {sortByDate.find(elem => elem.value === reportForm.sortByDate).label} </span>
                      </span>
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    {isSortSettingsOpen && <div ref={ node => this.sortNode = node } className="sort-settings">
                      {
                        sortByDate.map(elem => {
                          return <p key={ elem.value } className="sort-setting-item" onClick={ () => this.onValueChange(elem, 'sortByDate') }> {elem.label} </p>;
                        })
                      }
                    </div>
                    }
                    {isExportSettingsOpen && <div ref={ node => this.exportNode = node } className="export-settings">
                      <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                    </div>
                    }
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content report-content">
                      <table className="table table-striped">
                        <thead>
                          <tr style={ { backgroundColor: '#223679', color: "#FFFFFF" } }>
                            {
                              this.filterHeaders(reportsGenerated.headers).map((column, i) => {
                                return column.headerId !== "ip_country" && <th key={ i } className={ column.headerId === "success_rate" || this.isRightAligned(i) ? "text-right " : (column.headerType === "BUTTON" || column.headerType === "JSON" || column.headerType === "TEXT") ? "text-center" : "" }>
                                  {column.headerName}
                                </th>;
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {reportsGenerated.data.map((element, i) => {
                            return [reportsGenerated.isListDetailed && (
                              <tr key={ i } className={ element.mainRow ? "main-row" : "" }>
                                {element.content.map((tableData, j) => {
                                  return <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }>
                                    {tableData}
                                  </td>;
                                })
                                }
                              </tr>
                            ), reportsGenerated.isHistory && (
                              <tr key={ i }>
                                {this.filterData(element).map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" || reportsGenerated.headers[j].headerId === "ccAdditionalData") ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${!tableData || tableData === "{}" || tableData === "[]" ? "no-history" : "history"}` }
                                      onClick={ () => this.onOpenModalClick(tableData, reportsGenerated.headers[j].headerType === "JSON" ? "json" : "text") }
                                    >
                                      View
                                    </td>
                                  ) : reportsGenerated.headers[j].headerType === "JSON" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${(reportsGenerated.headers[j].headerId === "updateHistory" && tableData !== true) || roleId === "PROVIDER_ADMIN" ? "no-history" : "history"}` }
                                      onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element, tableData, reportsGenerated.headers[j].headerType === "JSON" ? "json" : "text") }
                                    >
                                      View
                                    </td>
                                  ) : reportsGenerated.headers[j].headerType === "BUTTON" ? (
                                    <td key={ i.toString() + j.toString() } className={ blockLoader.length && blockLoader.indexOf(reportsGenerated.data[i][this.getReportColumnIndex('label')]) > -1 ? "loading" : "" }>
                                      {blockLoader.length && blockLoader.indexOf(reportsGenerated.data[i][this.getReportColumnIndex('label')]) > -1 ?
                                        <img src={ LoadingIcon } alt="loading" />
                                        : (
                                          <Button 
                                            className={ `btn ${reportsGenerated.headers[j].headerName === 'Reject' ? 'red-button' : 'action-button'}`
                                            // eslint-disable-next-line eqeqeq
                                            && `btn ${this.filterHeaders(reportsGenerated.headers)[j].permission == "CHECK_STATUS" && element[element.length - 1] === "false" ? 'grey-button' : 'action-button'} ${reportForm.reportType === "PERFORMANCE" ? (tableData ? "green" : "orange") : "green"}` }
                                            disabled={ (reportForm.reportType === "PERFORMANCE" && (!this.checkAccess("BLOCK_REQUESTS") || (roleId === "WL_ADMIN" && reportsGenerated.headers[j].headerId === "blockIp"))) || isButtonLoading !== "" }
                                            onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element) }
                                          >
                                            {reportForm.reportType === "PERFORMANCE" ?
                                              (tableData ? "Unblock" : "Block")
                                              : isButtonLoading === reportsGenerated.data[i][this.getReportColumnIndex('id')] && loadingButtonType === reportsGenerated.headers[j].headerId ? (
                                                <img src={ LoadingIcon } alt="loading" width="20px" />
                                              ) : this.filterHeaders(reportsGenerated.headers)[j].headerName}
                                          </Button>
                                        )}
                                        
                                    </td>
                                  ) : (reportsGenerated.headers[j].headerName === "Status" && this.checkAccess("UPDATE_STATUS") && isChangeStatusPossible ) ? (
                                    <td key={ i.toString() + j.toString() } className="select">
                                      {editableTransactionField === "status" && editableTransactionId === reportsGenerated.data[i][this.getReportColumnIndex('id')] ? (
                                        <Select
                                          value={ tableData }
                                          clearable={ false }
                                          options={ this.getStatusSelectOptions(tableData) }
                                          onChange={ (e) => this.onChangeTxValue("status", reportsGenerated.data[i][this.getReportColumnIndex('id')], tableData, e.value) }
                                        />
                                      ) : (
                                        <span
                                          style={ { cursor: "pointer", padding: "0 10px", lineHeight: "43px" } }
                                          onDoubleClick={ () => this.onDoubleClick("status", tableData, reportsGenerated.data[i][this.getReportColumnIndex('id')], "", reportsGenerated.data[i][this.getReportColumnIndex('type')]) }
                                        >
                                          {tableData}
                                        </span>
                                      )}
                                    </td>
                                  ) : (reportsGenerated.headers[j].headerName === "Amount" &&
                                      this.checkAccess("UPDATE_STATUS") &&
                                      (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "DEPOSITS_PAYOUTS" || reportForm.reportType === "FRAUD_CHECK")) ? (
                                      <td key={ i.toString() + j.toString() } className="text-right input">
                                        {editableTransactionField === "amount" && editableTransactionId === reportsGenerated.data[i][this.getReportColumnIndex('id')] ? (
                                          <input
                                            type="number"
                                            value={ editableTransactionValue }
                                            onKeyDown={ (e) => this.handleEnterPress(e, reportsGenerated.data[i][this.getReportColumnIndex('status')]) }
                                            onChange={ (e) => this.onChangeTxValue("amount", reportsGenerated.data[i][this.getReportColumnIndex('id')], tableData, e.target.value) }
                                          />
                                        ) : (
                                          <span
                                            style={ { cursor: "pointer", padding: "0 10px", lineHeight: "43px" } }
                                            onDoubleClick={ () => this.onDoubleClick("amount", tableData, reportsGenerated.data[i][this.getReportColumnIndex('id')], reportsGenerated.data[i][this.getReportColumnIndex('status')], reportsGenerated.data[i][this.getReportColumnIndex('type')]) }
                                          >
                                            {tableData}
                                          </span>
                                        )}
                                      </td>
                                    ) : (
                                      reportsGenerated.headers[j].headerId !== "ip_country" && (
                                        <td
                                          key={ i.toString() + j.toString() }
                                          className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }
                                          style={ { minWidth: reportsGenerated.headers[j].headerId === "ip" ? "170px" : "100px" } }
                                        >
                                          { reportsGenerated.headers[j].headerId === "ip" && reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] && (
                                            <img
                                              alt={ reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] }
                                              style={ { width: "32px", marginRight: "3px" } }
                                              src={ `http://purecatamphetamine.github.io/country-flag-icons/3x2/${reportsGenerated.data[i][this.getReportColumnIndex('ip_country')]}.svg` }/>
                                          )}
                                          { tableData }
                                        </td>
                                      )
                                    );
                                })
                                }
                              </tr>
                            ), reportsGenerated.isList && (
                              <tr key={ i } style={ { backgroundColor: '#ffffff' } }>
                                {element.map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" && tableData) ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="history"
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : (
                                    <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }>
                                      {tableData}
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            )];
                          })}

                          {reportsGenerated.total && (
                            <tr className="main-row">
                              {reportsGenerated.total.map((elem, i) => {
                                return <td key={ i } className={ i === 0 ? "sticky-col" : "" }>
                                  {elem}
                                </td>;
                              })}
                            </tr>
                          )}
                        </tbody>
                      </table> 
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <span/>
              )}
              
              {clickButton && totalPageCount > 0 && (
                <Panel style={ { marginTop : "-13px", borderColor : "white", maxHeight: "105px", minHeight: "100px" } }>
                  <Panel.Body>
                    <div className="pagination">
                      <Pagination 
                        count={ totalPageCount } 
                        variant="outlined" 
                        shape="rounded" 
                        page={ Number(currentPage) }
                        onClick= { this.handleChangePage } 
                      />
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ this.getAlertButtonColor(alertType) }
            onConfirm={ this.onAlertConfirm }
          />
        )}
        <Modal
          title={ modalTitle }
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ isModalLoading }
        />
        <PromptModal
          title="Change status"
          message={ this.state.changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ this.state.changeTXPropertyLoading }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default GenerateReport;